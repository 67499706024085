import requests from '../../../request'
const baseUrl = '/purchase/return';
const region = 'cloud-api';

const ContentTypeEnum = {
    // json
    JSON: 'application/json;charset=UTF-8',
    // form-data qs
    FORM_URLENCODED: 'application/x-www-form-urlencoded;charset=UTF-8',
    // form-data  upload
    BLOB: 'multipart/form-data;charset=UTF-8',
}
// index.js

/**
 * 打印
 */
export function print(params) {
    return requests({
        method: 'get',
        url: baseUrl + '/print',
        params
    },
        {
            region,
        }
    );
}

/**
 * 订单列表
 */
export function query(params) {
    return requests({
        method: 'get',
        url: baseUrl + '/query',
        params,
    },
        {
            region,
        }
    );
}

/**
 * 导出
 */
export function exportList(data) {
    return requests({
        method: 'post',
        url: baseUrl + '/export',
        data,
    },
        {
            region,
            responseType: ResponseEnum.BLOB,
            contentType: ContentTypeEnum.FORM_URLENCODED,
        }
    );
}

/**
 * 查询详情
 */
export function get(id) {
    return requests({
        method: 'get',
        url: baseUrl,
        params:{id}
    },
        {
            region,
        }
    );
}

/**
 * 新增
 */
export function create(data) {
    return requests({
        method: 'post',
        url: baseUrl,
        data,
    },
        {
            region,
            contentType: ContentTypeEnum.JSON,
        }
    );
}

/**
 * 修改
 */
export function update(data) {
    return requests({
        method: 'put',
        url: baseUrl,
        data,
    },
        {
            region,
            contentType: ContentTypeEnum.JSON,
        }
    );
}

/**
 * 审核通过
 */
export function approvePass(data) {
    return requests({
        method: 'patch',
        url: baseUrl + '/approve/pass',
        data,
    },
        {
            region,
            contentType: ContentTypeEnum.JSON,
        }
    );
}

/**
 * 批量审核通过
 */
export function batchApprovePass(data) {
    return requests({
        method: 'patch',
        url: baseUrl + '/approve/pass/batch',
        data,
    },
        {
            region,
            contentType: ContentTypeEnum.JSON,
        }
    );
}

/**
 * 直接审核通过
 */
export function directApprovePass(data) {
    return requests({
        method: 'post',
        url: baseUrl + '/approve/pass/direct',
        data,
    },
        {
            region,
            contentType: ContentTypeEnum.JSON,
        }
    );
}

/**
 * 审核拒绝
 */
export function approveRefuse(data) {
    return requests({
        method: 'patch',
        url: baseUrl + '/approve/refuse',
        data,
    },
        {
            region,
            contentType: ContentTypeEnum.JSON,
        }
    );
}

/**
 * 批量审核拒绝
 */
export function batchApproveRefuse(data) {
    return requests({
        method: 'patch',
        url: baseUrl + '/approve/refuse/batch',
        data,
    },
        {
            region,
            contentType: ContentTypeEnum.JSON,
        }
    );
}

/**
 * 删除
 */
export function deleteById(params) {
    return requests({
        method: 'delete',
        url: baseUrl,
        params,
    },
        {
            region,
            contentType: ContentTypeEnum.FORM_URLENCODED,
        }
    );
}

/**
 * 批量删除
 */
export function deleteByIds(ids) {
    return requests({
        method: 'delete',
        url: baseUrl + '/batch',
        data: ids,
    },
        {
            region,
            contentType: ContentTypeEnum.JSON,
        }
    );
}