import requests from '../../../request'

const baseUrl = '/purchase/receive/sheet';
const selectorBaseUrl = '/selector';
const region = 'cloud-api';
const ContentTypeEnum = {
    // json
    JSON: 'application/json;charset=UTF-8',
    // form-data qs
    FORM_URLENCODED: 'application/x-www-form-urlencoded;charset=UTF-8',
    // form-data  upload
    BLOB: 'multipart/form-data;charset=UTF-8',
}

function selector(params) {
  return requests({
    method: 'get',
    url: selectorBaseUrl + '/receivesheet',
    params,
  });
}

function loadReceiveSheet(ids) {
  return requests({
    method: 'post',
    url: selectorBaseUrl + '/receivesheet/load',
    data: ids,
  }, {
    contentType: ContentTypeEnum.JSON,
    
  });
}

/**
 * 打印
 */
function print(id) {
  return requests({
    method: 'get',
    url: baseUrl + '/print',
    params: {
      id,
    },
  }, {
    
  });
}

/**
 * 订单列表
 */
function query(params) {
  return requests({
    method: 'get',
    url: baseUrl + '/query',
    params,
  }, {
    
  });
}

/**
 * 导出
 */
function exportList(data) {
  return requests({
    method: 'post',
    url: baseUrl + '/export',
    data,
  }, {
    
    responseType: ResponseEnum.BLOB,
    contentType: ContentTypeEnum.FORM_URLENCODED,
  });
}

/**
 * 查询详情
 */
function get(id) {
  return requests({
    method: 'get',
    url: baseUrl,
    params: {
      id,
    },
  }, {
    
  });
}

/**
 * 根据供应商ID查询默认付款日期
 */
function getPaymentDate(supplierId) {
  return requests({
    method: 'get',
    url: baseUrl + '/paymentdate',
    params: {
      supplierId,
    },
  }, {
    
  });
}

/**
 * 根据ID查询（采购退货业务）
 */
function getWithReturn(id) {
  return requests({
    method: 'get',
    url: baseUrl + '/return',
    params: {
      id,
    },
  }, {
    
  });
}

/**
 * 查询列表（采购退货业务）
 */
function queryWithReturn(params) {
  return requests({
    method: 'get',
    url: baseUrl + '/query/return',
    params,
  }, {
    
  });
}

/**
 * 加载列表（采购退货业务）
 */
function loadWithReturn(ids) {
  return requests({
    method: 'post',
    url: baseUrl + '/query/return/load',
    data: ids,
  }, {
    
    contentType: ContentTypeEnum.JSON,
  });
}

/**
 * 新增
 */
function create(data) {
  return requests({
    method: 'post',
    url: baseUrl,
    data,
  }, {
    
    contentType: ContentTypeEnum.JSON,
  });
}

/**
 * 修改
 */
function update(data) {
  return requests({
    method: 'put',
    url: baseUrl,
    data,
  }, {
    
    contentType: ContentTypeEnum.JSON,
  });
}

/**
 * 审核通过
 */
function approvePass(data) {
  return requests({
    method: 'patch',
    url: baseUrl + '/approve/pass',
    data,
  }, {
    
    contentType: ContentTypeEnum.JSON,
  });
}

/**
 * 批量审核通过
 */
function batchApprovePass(data) {
  return requests({
    method: 'patch',
    url: baseUrl + '/approve/pass/batch',
    data,
  }, {
    
    contentType: ContentTypeEnum.JSON,
  });
}

/**
 * 直接审核通过
 */
function directApprovePass(data) {
  return requests({
    method: 'post',
    url: baseUrl + '/approve/pass/direct',
    data,
  }, {
    
    contentType: ContentTypeEnum.JSON,
  });
}

/**
 * 审核拒绝
 */
function approveRefuse(data) {
  return requests({
    method: 'patch',
    url: baseUrl + '/approve/refuse',
    data,
  }, {
    
    contentType: ContentTypeEnum.JSON,
  });
}

/**
 * 批量审核拒绝
 */
function batchApproveRefuse(data) {
  return requests({
    method: 'patch',
    url: baseUrl + '/approve/refuse/batch',
    data,
  }, {
    
    contentType: ContentTypeEnum.JSON,
  });
}

/**
 * 删除
 */
function deleteById(params) {
  return requests({
    method: 'delete',
    url: baseUrl,
   params
  }, {
    contentType: ContentTypeEnum.FORM_URLENCODED,
  });
}

/**
 * 批量删除
 */
function deleteByIds(ids) {
  return requests({
    method: 'delete',
    url: baseUrl + '/batch',
    data: ids,
  }, {
    
    contentType: ContentTypeEnum.JSON,
  });
}

/**
 * 下载导入模板
 */
function downloadImportTemplate() {
  return requests({
    method: 'get',
    url: baseUrl + '/import/template',
  }, {
    responseType: ResponseEnum.BLOB,
    
  });
}

/**
 * 导入
 */
function importExcel(data) {
  return requests({
    method: 'post',
    url: baseUrl + '/import',
    data,
  }, {
    contentType: ContentTypeEnum.BLOB,
    
  });
}

/**
 * 下载约定支付导入模板
 */
function downloadImportPayTypeTemplate() {
  return requests({
    method: 'get',
    url: baseUrl + '/import/template',
  }, {
    responseType: ResponseEnum.BLOB,
    
  });
}

/**
 * 导入约定支付
 */
function importPayTypeExcel(data) {
  return requests({
    method: 'post',
    url: baseUrl + '/import/paytype',
    data,
  }, {
    contentType: ContentTypeEnum.BLOB,
    
  });
}
export  {
  selector,
  loadReceiveSheet,
  print,
  query,
  exportList,
  get,
  getPaymentDate,
  getWithReturn,
  queryWithReturn,
  loadWithReturn,
  create,
  update,
  approvePass,
  batchApprovePass,
  directApprovePass,
  approveRefuse,
  batchApproveRefuse,
  deleteById,
  deleteByIds,
  downloadImportTemplate,
  importExcel,
  downloadImportPayTypeTemplate,
  importPayTypeExcel,
};