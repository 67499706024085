<template>
  <div id="add">
    <van-form>
      <van-cell-group inset>
        <van-cell @click="showToast">
          <van-field
            autocomplete="off"
            v-model="form.scName"
            name="scName"
            label="仓库"
            readonly
            required
        /></van-cell>
        <van-cell @click="showToast">
          <van-field
            autocomplete="off"
            v-model="form.supplierName"
            name="supplierName"
            label="供应商"
            readonly
            required
        /></van-cell>
        <van-cell>
          <van-field
            autocomplete="off"
            v-model="form.purchaserName"
            name="purchaserName"
            label="采购员"
        /></van-cell>
        <van-cell @click="openDateTimePicker('form', 'paymentDate')">
          <van-field
            autocomplete="off"
            v-model="form.paymentDate"
            name="paymentDate"
            required
            label="付款日期"
        /></van-cell>
        <van-cell @click="showPurchaseOrderSelect">
          <van-field
            autocomplete="off"
            v-model="form.receiveSheetId"
            name="receiveSheetId"
            required
            label="采购收货单"
        /></van-cell>
      </van-cell-group>
      <van-form style="margin: 16px">
        <div class="action">
          <Van-icon
            name="add-o"
            type="primary"
            @click="toShowProductSelect"
          ></Van-icon>
        </div>
        <CustomTable
          :scrollWidth="600"
          :showPagination="false"
          :columns="columns"
          :tableData="tableData"
        ></CustomTable>
      </van-form>

      <div style="margin: 16px">
        <van-form>
          <van-cell>合计</van-cell>
          <van-cell-group>
            <van-cell
              ><van-field
                autocomplete="off"
                label="退货数量"
                readonly
                v-model="form.totalNum"
                type="number"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="赠品数量"
                readonly
                type="number"
                v-model="form.giftNum"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="含税总金额"
                readonly
                type="number"
                v-model="form.totalAmount"
            /></van-cell>
          </van-cell-group>
        </van-form>
        <!-- 备注 -->
        <van-field
          autocomplete="off"
          v-model="form.description"
          disabled
          rows="2"
          autosize
          label="备注"
          type="textarea"
        />
        <van-cell class="actions-btn">
          <van-button type="primary" @click="onAddConfirm" round block
            >保存</van-button
          >
          <van-button
            type="info"
            @click="toDirectApprovePass"
            round
            block
            v-permission="'purchase:return:approve'"
            >直接审批通过</van-button
          >
          <van-button @click="close" type="default" round block
            >关闭</van-button
          >
        </van-cell>
      </div>
    </van-form>
    <ProductSelect
      type="order"
      columnsType="checkbox"
      :scId="this.form.scId"
      :visible="showProductSelect"
      @confirm="onSelectProductConfirm"
    ></ProductSelect>
    <DateTimePicker
      value-format="yyyy-MM-dd"
      :showPicker="showDatePicker"
      @confirm="onDatePickerConfirm"
      @cancel="showDatePicker = false"
    ></DateTimePicker>
    <ReceiveSheetSelect
      :visible="receiveSheetSelectVisible"
      @confirm="receiveSheetSelectConfirm"
    ></ReceiveSheetSelect>
  </div>
</template>

<script>
import {
  create,
  directApprovePass,
} from "../../../service/api/purchase/return";
import SupplierSelect from "../../../components/supplierSelect..vue";
import ProductSelect from "../../../components/productSelect.vue";
import StockSelect from "../../../components/stockSelect.vue";
import CustomTable from "../../../components/customTable.vue";
import DateTimePicker from "../../../components/dateTimePicker.vue";
import { Notify, Toast } from "vant";
import moment from "moment";
import ReceiveSheetSelect from "../../../components/receiveSheetSelect.vue";
import {
  getWithReturn,
  getPaymentDate,
} from "../../../service/api/purchase/receive";

export default {
  name: "index",
  components: {
    CustomTable,
    ProductSelect,
    StockSelect,
    SupplierSelect,
    DateTimePicker,
    ReceiveSheetSelect,
  },
  data() {
    return {
      receiveSheetSelectVisible: false,
      showDatePicker: false,
      showDatePicker: false,
      stockSelectVisible: false,
      supplierSelectVisible: false,
      showProductSelect: false,
      datePickType: "product",
      form: {},
      tableData: [],
      tableData2: [],
      columns: [
        {
          field: "productName",
          key: "productName",
          title: "商品名称",
          width: 100,
          fixed: "left",
        },
        {
          field: "productCode",
          key: "productCode",
          title: "商品编号",
          width: 80,
        },
        { field: "unit", key: "unit", title: "单位", width: 80 },
        { field: "spec", key: "spec", title: "规格", width: 80 },
        {
          field: "categoryName",
          key: "categoryName",
          title: "商品类目",
          width: 80,
        },
        {
          field: "isGift",
          key: "isGift",
          title: "是否赠品",
          width: 50,
          formatter: ({ cellValue }) => {
            return cellValue ? "是" : "否";
          },
        },
        { field: "brandName", key: "brandName", title: "商品品牌", width: 80 },
        {
          field: "purchasePrice",
          key: "purchasePrice",
          title: "采购价（元）",
          align: "right",
          width: 100,
        },
        {
          field: "taxCostPrice",
          key: "taxCostPrice",
          title: "含税成本价（元）",
          width: 100,
        },
        { field: "stockNum", key: "stockNum", title: "库存数量", width: 100 },
        {
          field: "receiveNum",
          key: "receiveNum",
          title: "收货数量",
          width: 100,
        },
        {
          field: "remainNum",
          key: "remainNum",
          title: "剩余退货数量",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const value = this.$utils.sub(row.remainNum, row.returnNum);
            return <span>{value}</span>;
          },
        },
        {
          field: "returnNum",
          key: "returnNum",
          title: "退货数量",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <van-field
                autocomplete="off"
                name="returnNum"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "taxAmount",
          key: "taxAmount",
          title: "含税金额",
          align: "right",
          width: 80,
        },
        {
          field: "description",
          key: "description",
          title: "备注",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <van-field
                autocomplete="off"
                name="description"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "",
          key: "e",
          title: "操作",
          width: "",
          center: "left",
          fixed: "right",
          width: 50,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span class="action">
                <van-icon
                  name="delete-o"
                  on-click={() => this.deleteRow(rowIndex)}
                ></van-icon>
              </span>
            );
          },
        },
      ],
    };
  },
  watch: {
    tableData: {
      handler(newVal, oldVal) {
        this.calcSum();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    close() {
      this.$router.push({ path: "/purchase/return" });
    },
    showToast() {
      Toast.fail("请先选择采购收货单");
    },
    toShowProductSelect() {
      if (!this.form.scId) {
        Notify({ type: "warning", message: "请先选择仓库" });
        return;
      }
      this.showProductSelect = true;
    },
    genParams() {
      const params = {
        scId: this.form.scId,
        supplierId: this.form.supplierId,
        purchaserId: this.form.purchaserId || "",
        paymentDate: this.form.paymentDate || "",
        receiveSheetId: this.form.receiveSheetId,
        description: this.form.description,
        required: true,
        products: this.tableData
          .filter((t) => this.$utils.isIntegerGtZero(t.returnNum))
          .map((t) => {
            const product = {
              productId: t.productId,
              returnNum: t.returnNum,
              description: t.description,
            };

            if (t.isFixed) {
              product.receiveSheetDetailId = t.id;
            }

            return product;
          }),
      };
      return params;
    },
    addDays(date, days) {
      if (!date || !days) {
        return "";
      }
      const result = new Date(date);
      result.setDate(result.getDate() + Number(days));
      return moment(result).format("YYYY-MM-DD");
    },
    // 校验数据
    validData() {
      if (this.$utils.isEmpty(this.form.scId)) {
        this.$utils.createError("仓库不允许为空！");
        return false;
      }

      if (this.$utils.isEmpty(this.form.supplierId)) {
        this.$utils.createError("供应商不允许为空！");
        return false;
      }

      if (this.form.allowModifyPaymentDate) {
        if (this.$utils.isEmpty(this.form.paymentDate)) {
          this.$utils.createError("付款日期不允许为空！");
          return false;
        }
      }

      if (this.$utils.isEmpty(this.form.receiveSheetId)) {
        this.$utils.createError("采购收货单不允许为空！");
        return false;
      }

      if (this.$utils.isEmpty(this.tableData)) {
        this.$utils.createError("请录入商品！");
        return false;
      }

      for (let i = 0; i < this.tableData.length; i++) {
        const product = this.tableData[i];

        if (this.$utils.isEmpty(product.productId)) {
          this.$utils.createError("第" + (i + 1) + "行商品不允许为空！");
          return false;
        }

        if (this.$utils.isEmpty(product.purchasePrice)) {
          this.$utils.createError("第" + (i + 1) + "行商品采购价不允许为空！");
          return false;
        }

        if (!this.$utils.isFloat(product.purchasePrice)) {
          this.$utils.createError("第" + (i + 1) + "行商品采购价必须为数字！");
          return false;
        }

        if (product.isGift) {
          if (parseFloat(product.purchasePrice) !== 0) {
            this.$utils.createError("第" + (i + 1) + "行商品采购价必须等于0！");
            return false;
          }
        } else {
          if (!this.$utils.isFloatGtZero(product.purchasePrice)) {
            this.$utils.createError("第" + (i + 1) + "行商品采购价必须大于0！");
            return false;
          }
        }

        if (!this.$utils.isNumberPrecision(product.purchasePrice, 2)) {
          this.$utils.createError(
            "第" + (i + 1) + "行商品采购价最多允许2位小数！"
          );
          return false;
        }

        if (!this.$utils.isEmpty(product.returnNum)) {
          if (!this.$utils.isInteger(product.returnNum)) {
            this.$utils.createError(
              "第" + (i + 1) + "行商品退货数量必须为整数！"
            );
            return false;
          }

          if (product.isFixed) {
            if (!this.$utils.isIntegerGeZero(product.returnNum)) {
              this.$utils.createError(
                "第" + (i + 1) + "行商品退货数量不允许小于0！"
              );
              return false;
            }
          } else {
            if (!this.$utils.isIntegerGtZero(product.returnNum)) {
              this.$utils.createError(
                "第" + (i + 1) + "行商品退货数量必须大于0！"
              );
              return false;
            }
          }

          if (product.isFixed) {
            if (product.returnNum > product.remainNum) {
              this.$utils.createError(
                "第" +
                  (i + 1) +
                  "行商品累计退货数量为" +
                  (product.receiveNum - product.remainNum) +
                  "，剩余退货数量为" +
                  product.remainNum +
                  "，本次退货数量不允许大于" +
                  product.remainNum +
                  "！"
              );
              return false;
            }
          }
        } else {
          if (!product.isFixed) {
            this.$utils.createError(
              "第" + (i + 1) + "行商品退货数量不允许为空！"
            );
            return false;
          }
        }
      }

      if (
        this.tableData.filter((item) =>
          this.$utils.isIntegerGtZero(item.returnNum)
        ).length === 0
      ) {
        this.$utils.createError("采购收货单中的商品必须全部或部分退货！");
        return false;
      }

      return true;
    },
    toDirectApprovePass() {
      if (!this.validData()) {
        return;
      }
      const checkStockNumArr = [];
      this.tableData
        .filter((item) => this.$utils.isIntegerGtZero(item.returnNum))
        .forEach((item) => {
          if (
            checkStockNumArr.map((v) => item.productId).includes(item.productId)
          ) {
            checkStockNumArr
              .filter((v) => v.productId === item.productId)
              .forEach((v) => {
                v.returnNum = this.$utils.add(v.returnNum, item.returnNum);
              });
          } else {
            checkStockNumArr.push({
              productId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              stockNum: item.stockNum,
              returnNum: item.returnNum,
            });
          }
        });

      const unValidStockNumArr = checkStockNumArr.filter(
        (item) => item.stockNum < item.returnNum
      );
      if (!this.$utils.isEmpty(unValidStockNumArr)) {
        this.$utils.createError(
          "商品（" +
            unValidStockNumArr[0].productCode +
            "）" +
            unValidStockNumArr[0].productName +
            "当前库存为" +
            unValidStockNumArr[0].stockNum +
            "，总退货数量为" +
            unValidStockNumArr[0].returnNum +
            "，无法完成采购退货！"
        );
        return false;
      }
      const params = this.genParams();
      directApprovePass(params).then((res) => {
        Toast.success(res.msg);
        this.$router.push({ path: "/purchase/return" });
      });
    },
    onAddConfirm() {
      if (!this.validData()) {
        return;
      }
      const params = this.genParams();
      console.log(params);
      create(params).then((res) => {
        Toast.success(res.msg);
        this.$router.push({ path: "/purchase/return" });
      });
    },
    // 计算汇总数据
    calcSum() {
      let totalNum = 0;
      let giftNum = 0;
      let totalAmount = 0;

      this.tableData
        .filter((t) => {
          return (
            this.$utils.isFloatGeZero(t.purchasePrice) &&
            this.$utils.isIntegerGeZero(t.returnNum)
          );
        })
        .forEach((t) => {
          const num = parseInt(t.returnNum);
          if (t.isGift) {
            giftNum = this.$utils.add(giftNum, num);
          } else {
            totalNum = this.$utils.add(totalNum, num);
          }

          totalAmount = this.$utils.add(
            totalAmount,
            this.$utils.mul(num, t.purchasePrice)
          );
        });

      this.form.totalNum = totalNum;
      this.form.giftNum = giftNum;
      this.form.totalAmount = totalAmount;
      console.log(this.form.totalNum, this.form.giftNum, this.form.totalAmount);
    },
    showPurchaseOrderSelect() {
      this.receiveSheetSelectVisible = true;
    },
    emptyProduct() {
      return {
        id: this.$utils.uuid(),
        productId: "",
        productCode: "",
        productName: "",
        skuCode: "",
        externalCode: "",
        unit: "",
        spec: "",
        categoryName: "",
        brandName: "",
        purchasePrice: 0,
        taxCostPrice: "",
        stockNum: "",
        receiveNum: "",
        remainNum: "",
        returnNum: "",
        taxRate: "",
        isGift: true,
        taxAmount: "",
        description: "",
        isFixed: false,
        products: [],
      };
    },
    receiveSheetSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.receiveSheetSelectVisible = false;
        return;
      }
      getWithReturn(row.id)
        .then((res) => {
          const data = res.data;
          const tableData = this.tableData.filter((item) => !item.isFixed);
          let receiveSheetDetails = data.details || [];
          receiveSheetDetails = receiveSheetDetails.map((item) => {
            item.isFixed = true;

            return Object.assign(this.emptyProduct(), item);
          });

          this.tableData = [...receiveSheetDetails, ...tableData];

          this.form.scId = data.scId;

          this.form.supplierId = data.supplierId;

          if (!this.$utils.isEmpty(data.purchaserId)) {
            this.form.purchaserId = data.purchaserId;
          }

          this.supplierChange(this.form.supplierId);
        })

        .finally(() => {
          this.loading = false;
        });
      this.receiveSheetSelectVisible = false;
      this.form.receiveSheetId = row.id;
      this.form.scName = row.scName;
      this.form.scId = row.scCode;
      this.form.supplierName = row.supplierName;
      this.form.supplierId = row.supplierCode;
    },
    supplierChange(supplierId) {
      getPaymentDate(supplierId).then((res) => {
        console.log("付款日期获取", res.data.paymentDate);
        this.form.paymentDate = res.data.paymentDate || "";
        this.form.allowModifyPaymentDate = res.data.allowModify;
      });
    },
    inputChange(rowIndex, field, val) {
      this.$set(this.tableData, rowIndex, {
        ...this.tableData[rowIndex],
        [field]: val,
      });
    },
    openDateTimePicker(type = "product", index) {
      this.datePickType = type;
      this.index = index;
      this.showDatePicker = true;
    },
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    onSelectProductConfirm(row) {
      if (this.showProductSelect) {
        this.showProductSelect = false;
      }
      if (row.length) {
        row.forEach((el) => {
          this.tableData.push({
            ...el,
          });
        });
      }
    },
    onDatePickerConfirm(val) {
      if (!val) return;
      if (this.datePickType == "product") {
        this.$set(this.tableData, this.index, {
          ...this.tableData[this.index],
          productionDate: val,
        });
      } else {
        this.form[this.index] = val;
      }
      this.showDatePicker = false;
      this.index = 0;
    },
  },
};
</script>
<style scoped>
.van-cell {
  padding: 5px 0px 5px 17px;
}
#add {
  position: relative;
}
::v-deep .van-field__body {
  height: 100%;
}
.actions-btn {
  .van-button {
    margin: 5px 5px 0 0;
  }
}
.action {
  background-color: #fff;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 26px;
  height: 36px;
}
</style>
